<template>
  <div>
    <table width="100%">
      <tr>
        <td>
          <v-select
            :items="doorStatusList"
            v-model="doorStatus"
            hide-details
            :disabled="pageLoading"
            item-color="cyan"
            v-on:change="getCustomerDoors()"
            class="pt-0 mt-0 listing-status main-listing-status-filter color-custom-blue font-weight-700"
            item-text="text"
            item-value="value"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.text" />
                <!--  <v-list-item-subtitle v-text="item.description" /> -->
              </v-list-item-content>
              <v-list-item-action>
                <v-chip :color="item.color" :text-color="item.textcolor">
                  <template v-if="item.value == 'all'">{{
                    item.all_doors
                  }}</template>
                  <template v-else>{{ item.door_status_count }}</template>
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </td>
        <td v-if="!customerDetail">
          <v-select
            :disabled="pageLoading"
            :items="allCustomers"
            v-model="filterCustomer"
            hide-details
            item-color="cyan"
            class="pt-0 mt-0 mx-4 listing-status main-listing-status-filter color-custom-blue font-weight-700"
            item-text="display_name"
            item-value="id"
            v-on:change="getCustomerDoors()"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.display_name"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip color="cyan white--text">
                  {{ item.product_count }}
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </td>
        <td>
          <v-select
            :disabled="pageLoading"
            :items="allDoorTypes"
            v-model="filterDoorType"
            hide-details
            item-color="cyan"
            class="pt-0 mt-0 listing-status main-listing-status-filter color-custom-blue font-weight-700"
            item-text="display_name"
            item-value="id"
            v-on:change="getCustomerDoors()"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.display_name"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip color="cyan white--text">
                  {{ item.product_count }}
                </v-chip>
              </v-list-item-action>
            </template>
          </v-select>
        </td>
        <td width="40%"></td>
        <td align="right">
          <div class="d-flex">
            <v-text-field
              dense
              filled
              placeholder="Search"
              solo
              v-model="search"
              flat
              :disabled="pageLoading"
              :loading="pageLoading"
              hide-details
              clearable
              v-on:keypress.enter="
                currentPage = 1;
                getCustomerDoors();
              "
              v-on:click:clear="
                search = null;
                getCustomerDoors();
              "
              color="cyan"
            />
            <v-btn
              v-on:click="
                currentPage = 1;
                getCustomerDoors();
              "
              :disabled="pageLoading"
              :loading="pageLoading"
              style="min-height: 32px"
              class="custom-bold-button ml-4"
              depressed
              color="cyan white--text"
              >Search</v-btn
            >
          </div>
        </td>
      </tr>
    </table>
    <div class="custom-listing-table">
      <v-simple-table class="simple-table custom-table-height" fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="simple-table-th" width="100" align="center">Image</th>
              <th class="simple-table-th" width="200">Quotation #</th>
              <th class="simple-table-th" width="200">Visit #</th>
              <th class="simple-table-th">Door Type</th>
              <th class="simple-table-th">Serial No.</th>
              <th class="simple-table-th">Door's Info</th>
              <th class="simple-table-th">Created Time</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="customerDoors?.length > 0">
              <tr
                class="alternate-listing-row"
                v-for="(data, index) in customerDoors"
                :key="`customer-door-row-${index}`"
                v-on:click="routeToDetail(data)"
                :class="{ 'table-in-active-row': !data.activated }"
              >
                <td class="simple-table-td" width="100" align="center">
                  <v-img
                    class="customer-image"
                    width="70"
                    height="70"
                    contain
                    :lazy-src="$defaultImage"
                    :src="$assetAPIURL(data.door_image)"
                  >
                  </v-img>
                </td>
                <td class="simple-table-td">
                  <template v-if="data.quotation_relation?.id">
                    <v-chip
                      style="height: 20px"
                      v-on:click.stop.prevent="
                        routeToQuoteDetail(data.quotation_relation.id)
                      "
                      outlined
                      label
                      color="blue darken-4"
                      >{{ data.quotation_relation.barcode }}</v-chip
                    >
                    <p class="my-0">{{ data.quotation_relation.job_title }}</p>
                  </template>
                  <em v-else class="text-muted"> no quotation </em>
                </td>
                <td class="simple-table-td">
                  <template v-if="data.visit_relation?.id">
                    <v-chip
                      style="height: 20px"
                      v-on:click.stop.prevent="
                        routeToVisitDetail(data.visit_relation.id)
                      "
                      outlined
                      label
                      color="blue darken-4"
                      >{{ data.visit_relation.barcode }}</v-chip
                    >
                    <p class="my-0">{{ data.visit_relation.title }}</p>
                  </template>
                  <em v-else class="text-muted"> no visit </em>
                </td>
                <td class="simple-table-td">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Door Type: </b>
                    {{ data?.door_type_relation?.title ?? "" }}
                  </p>
                </td>
                <td class="simple-table-td">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Serial No.: </b> {{ data?.serial_number || " - " }}
                  </p>
                </td>
                <td class="simple-table-td">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b> {{ data.name || " - " }}
                  </p>
                  <p v-if="data.length" class="m-0 custom-nowrap-ellipsis">
                    <b>Length: </b>
                    {{ data.length || "" }}
                    {{ data.length_unit != 0 ? data.length_unit : "" }}
                  </p>
                  <p v-if="data.width" class="m-0 custom-nowrap-ellipsis">
                    <b>Width: </b>
                    {{ data.width || "" }}
                    {{ data.width_unit != 0 ? data.width_unit : "" }}
                  </p>
                  <p v-if="data.height" class="m-0 custom-nowrap-ellipsis">
                    <b>Height: </b>
                    {{ data.height || "" }}
                    {{ data.height_unit != 0 ? data.height_unit : "" }}
                  </p>
                </td>
                <td class="simple-table-td">
                  <TableActivity
                    v-if="!lodash.isEmpty(data.added_by)"
                    :data="data"
                  >
                    <template v-slot:display_name>
                      {{ data.added_by.display_name }}
                    </template>
                    <template v-slot:date_time>
                      {{ data.created_at }}
                    </template>
                    <template v-slot:format_date_time>
                      {{ formatDate(data.added_at) }}
                      {{ formatDateTime(data.added_at) }}
                    </template>
                  </TableActivity>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td :colspan="7" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no door at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="d-flex align-center justify-end">
      <v-pagination
        :total-visible="7"
        v-model="currentPage"
        :length="totalPages"
        color="cyan"
        :disabled="pageLoading"
        v-on:input="getCustomerDoors()"
      />
    </div>
  </div>
</template>

<script>
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [CommonMixin],
  props: {
    /*customerId: {
      type: Number,
      default: 0
    },*/
    customerDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customerId: 0,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalPages: 0,
      rowsOffset: 0,
      filterCustomer: 0,
      filterDoorType: 0,
      pageLoading: false,
      search: null,
      doorStatus: "all",
      allCustomers: [],
      allDoorTypes: [],
      customerDoors: [],
      doorStatusList: [],
    };
  },
  methods: {
    routeToDetail(row) {
      this.$router.push(
        this.getDefaultRoute("door.detail", {
          params: { id: row?.id },
        })
      );
    },
    routeToQuoteDetail(id) {
      const url = this.$router.resolve(
        this.getDefaultRoute("quotation.detail", {
          params: { id },
        })
      );
      window.open(url.href, "_blank");
    },
    routeToVisitDetail(id) {
      const url = this.$router.resolve(
        this.getDefaultRoute("visit.detail", {
          params: { id },
        })
      );
      window.open(url.href, "_blank");
    },
    getCustomerDoors() {
      this.pageLoading = true;
      this.customerDoors = [];
      this.perPage = 0;
      this.totalPages = 0;
      this.totalRows = 0;
      this.rowsOffset = 0;
      this.$store
        .dispatch(QUERY, {
          url: "door",
          data: {
            current_page: this.currentPage,
            per_page: this.perPage,
            status: this.doorStatus,
            customer: this.customerId,
            door_type: this.filterDoorType,
            search: this.search,
          },
        })
        .then(({ data }) => {
          this.doorStatusList = data.status_list;
          this.customerDoors = data.rows;
          this.allCustomers = data?.all_customers ?? [];
          this.allDoorTypes = data?.all_door_types ?? [];
          this.currentPage = data.current_page;
          this.perPage = data.per_page;
          this.totalPages = Math.ceil(data.total_rows / data.per_page);
          this.totalRows = data.total_rows;
          this.rowsOffset = data.offset;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.customerId = this.$route.params.id;
  },
  mounted() {
    this.getCustomerDoors();
  },
  components: {
    TableActivity,
  },
};
</script>